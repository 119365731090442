// 기본정보 > 품목
import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import styled from "styled-components"

// import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

import { dateFormat } from "../../utils/util"

import SEO from "../seo"
import Layout from "../component/Layout"

import AdminContainer from "../../containers/AdminContainer"

import { SkyButton, YellowButton, GrayButton, GreenButton } from "../component/common/Button"
import MultiComboBox from "../component/common/MultiComboBox"
import MapsJavacript from "../component/common/MapsJavascript" // MapsJavascript API - 차건담 2023.08.17
import DateBox from "../component/common/DateBox"
import InputBox from "../component/common/InputBox"
import TableCheckBox from "../component/common/TableCheckBox"
import { TableComponent } from "../component/common/Table"

const FarmMap = (props) => {
  const { 
    
    initDataList, getDataList, saveDataList, 
    farmList : dataList, FARM_LIST : actionType, //AGR_APPLY_LIST, REJ_APPLY_LIST, OUT_APPLY_LIST, 
    // setApplyList,

  } = props

  const temp1 = dateFormat(new Date(),'YYYY-MM-01')
  const temp2 = dateFormat(new Date(),'YYYY-MM-DD')

  const [dateFr, setDateFr] = useState( temp1 )
  const [dateTo, setDateTo] = useState( temp2 )

  const [farmName, setFarmName] = useState("")
  const [applyStatus, setApplyStatus] = useState(0)

  const [farmInfo, setFarmInfo] = useState(null)

  const [checks, setChecks] = useState([])

  useEffect(() => {

    getData()

    return async () => await initDataList( actionType )

  }, [])

  const getData = async () => {
  }

  const onClickSearch = async () => {

    const data = {

      dateFr : '', 
      dateTo : '99991231',
      farmName,
      applyStatus,

    }
    
    await getDataList( actionType, data )
    setFarmInfo(null)

  }

  return (
    <Layout>
      <SEO title="지역별농장정보" />
      <Container>
        <div style={{display:'flex', width: '100%', height: '100%'}} >
          <WhiteBoxLeft>
            {/* <MapBox dataList={dataList} dataInfo={farmInfo} /> */}
            {/* <MapsJavacript/> */}
            <MapsJavacript dataList={dataList} dataInfo={farmInfo}/>
          </WhiteBoxLeft>
          <WhiteBox>
            <SearchBox>
              <div>
                <Text>농장명</Text>
                <InputBox style={{ width: "260px" }} 
                  placeholder={""} 
                  type="text"
                  disabled={false}
                  value={farmName} 
                  onChange={(e)=>setFarmName(e.target.value)} 
                />
              </div>
              <YellowButton onClick={onClickSearch}>조회</YellowButton>
            </SearchBox>
            
            <TableComponent
              headList={[
                { title: "지역", type: "text" },
                { title: "농장명", type: "text" },
                { title: "농장주명", type: "text" },
                { title: "전화번호", type: "" },
                { title: "핸드폰번호", type: "" },
                { title: "분야", type: "" },
                { title: "대표품목", type: "text" },
              ]}
              bodyList={dataList.map((i) => ({
                seq: i.farmSeq, 
                regionCode: i.regionCode,
                farmName: i.farmName,
                farmOwner: i.farmOwner,
                cellNo: i.cellNo,
                teleNo: i.teleNo,
                bizpartName: i.bizpartName,
                itemName: i.itemName,
              }))}
              onClick={(row)=>{ 
                setFarmInfo(row) 
              }}
            />
          </WhiteBox>
        </div>
      </Container>
    </Layout>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

const WhiteBoxLeft = styled.div`
  background-color: #fff;
  overflow-y: hidden;
  display: flex;
  margin-top: 5px;
  margin-right: 5px;
  border: 5px solid #fff;
  border-radius: 9px;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
  width: 30%;
  position: relative;
`
const WhiteBox = styled.div`
  background-color: #fff;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 5px;
  padding: 28px 32px 32px;
  border: 1px solid #fff;
  border-radius: 9px;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
  width: 60%;
`
const SearchBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`
const Text = styled.p`
  width: 80px;
  color: #555555;
  font-size: 14px;
`

export default AdminContainer(FarmMap);

