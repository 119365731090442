import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import actions from "../actions"

const { initDataList, getDataList, getDataInfiniteList, saveDataList, getSMinorComboList, getUMinorComboList, getFarmComboList } =
  actions.CommonAction

const {
  PROFIT_LIST,
  FAULT_LIST,
  FAULT_INFO,
  FARM_LIST,
  RECP_FAULT_INFO,
  DONE_FAULT_INFO,
  APPLY_LIST,
  AGR_APPLY_LIST,
  REJ_APPLY_LIST,
  OUT_APPLY_LIST,
  LOGIN_LIST,
  ERROR_LIST,
  JOIN_LIST,
  JOIN_SUB_LIST,

  setApplyList,
} = actions.AdminAction

// redux state > component mapping
const mapStateToProps = (state) => ({
  profitList: state.CommonReducer.profitList || PROFIT_LIST.initData,
  PROFIT_LIST,

  farmList: state.CommonReducer.farmList || FARM_LIST.initData,
  FARM_LIST,

  faultList: state.CommonReducer.faultList || FAULT_LIST.initData,
  FAULT_LIST,
  faultInfo: state.CommonReducer.faultInfo || FAULT_INFO.initData,
  FAULT_INFO,
  RECP_FAULT_INFO,
  DONE_FAULT_INFO,
  applyList: state.CommonReducer.applyList || APPLY_LIST.initData,
  APPLY_LIST,
  AGR_APPLY_LIST,
  REJ_APPLY_LIST,
  OUT_APPLY_LIST,
  loginList: state.CommonReducer.loginList || LOGIN_LIST.initData,
  LOGIN_LIST,
  errorList: state.CommonReducer.errorList || ERROR_LIST.initData,
  ERROR_LIST,
  joinList: state.CommonReducer.joinList || JOIN_LIST.initData,
  JOIN_LIST,
  joinSubList: state.CommonReducer.joinSubList || JOIN_SUB_LIST.initData,
  JOIN_SUB_LIST,

  sminorComboList: state.CommonReducer.sminorComboList,
  uminorComboList: state.CommonReducer.uminorComboList,
  farmComboList: state.CommonReducer.farmComboList,
})

// redux action > component mapping
const mapDispatchToProps = (dispatch) => ({
  setApplyList: (param) => dispatch(setApplyList(param)),

  initDataList: (actionType) => dispatch(initDataList(actionType)),
  getDataList: (actionType, param) => dispatch(getDataList(actionType, param)),
  getDataInfiniteList: (actionType, param) => dispatch(getDataInfiniteList(actionType, param)),
  saveDataList: (actionType, param) => dispatch(saveDataList(actionType, param)),

  getSMinorComboList: () => dispatch(getSMinorComboList()),
  getUMinorComboList: () => dispatch(getUMinorComboList()),
  getFarmComboList: () => dispatch(getFarmComboList()),
})

const WithHoc = (ChildComponent) => (props) => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
