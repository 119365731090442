import axios from "axios"
import { navigate } from "gatsby"
import cookie from "react-cookies"
import { API_URL, TOKEN_ID } from "config"
import { configureStore } from "../store"
import { setServerErrorPopup } from "../actions/ConfigAction"
import { debug } from "utils/util"

const apiFunction = (method) => {
  return async (url, { body = {}, header = {}, token = true, options = {} } = {}) => {
    try {
      let headers = {
        Accept: "application/json",
        ...header,
      }
      if (token) {
        headers.Authorization = `Bearer ${localStorage.getItem(TOKEN_ID)}`
      }
      const result = await axios({
        method: method,
        url: API_URL + url,
        data: body,
        headers,
        responseType: options.responseType,
      })

      debug(result)
      return result
    } catch (err) {
      if (err.response) {
        debug(err.response)
        if (err.response.status === 400) {
          configureStore.dispatch(setServerErrorPopup({ open: true, text: err.response.data.message }))
          return err.response
        } else if (err.response.status === 401) {
          localStorage.removeItem(TOKEN_ID)
          navigate("/app/login")
          return err.response
        } else if (err.response.status === 403) {
          localStorage.removeItem(TOKEN_ID)
          navigate("/app/login")
          return err.response
        } else if (err.response.status === 412) {
          configureStore.dispatch(setServerErrorPopup({ open: true, text: err.response.data.message }))
          navigate("/app/login")
          localStorage.removeItem(TOKEN_ID)
          return err.response
        } else if (err.response.status === 500) {
          configureStore.dispatch(setServerErrorPopup({ open: true, text: err.response.data.message }))
          return err.response
        }
      }
    } finally {
      //
    }
  }
}

const api = {
  post: apiFunction("POST"),
  get: apiFunction("GET"),
  delete: apiFunction("DELETE"),
  patch: apiFunction("PATCH"),
}

export default api
