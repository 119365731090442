import { handleActions } from "redux-actions"
import actions from "../actions"

// action type load
const { SET_DATA_LIST, SET_DATA_INFINITE_LIST, SET_SMINOR_COMBO_LIST, SET_UMINOR_COMBO_LIST, SET_FARM_COMBO_LIST } = actions.CommonAction

const { SET_APPLY_LIST } = actions.AdminAction

// default state value
const initialState = {
  sminorComboList: [],
  uminorComboList: [],
  farmComboList: [],
}

// handle action
export default handleActions(
  {
    [SET_APPLY_LIST]: (state, action) => {
      state = {
        ...state,
        applyList: action.payload,
      }

      return state
    },

    [SET_DATA_LIST]: (state, action) => {
      state = {
        ...state,
        ...action.payload,
      }

      return state
    },

    [SET_DATA_INFINITE_LIST]: (state, action) => {
      state = {
        ...state,
        ...{ [Object.keys(action.payload)[0]]: [...state[Object.keys(action.payload)[0]], ...action.payload[Object.keys(action.payload)[0]]] },
      }

      return state
    },

    [SET_SMINOR_COMBO_LIST]: (state, action) => {
      state = {
        ...state,
        sminorComboList: action.payload,
      }

      return state
    },

    [SET_UMINOR_COMBO_LIST]: (state, action) => {
      state = {
        ...state,
        uminorComboList: action.payload,
      }

      return state
    },

    [SET_FARM_COMBO_LIST]: (state, action) => {
      state = {
        ...state,
        farmComboList: action.payload,
      }

      return state
    },
  },
  initialState
)
