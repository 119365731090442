import React from "react"
import styled from "styled-components"
import icon_star_yellow from "images/star_yellow.png"
import icon_star_empty from "images/star_empty.png"

const PageName = ({ name, state, setState }) => {
  return (
    <TitleView>
      <Title>{name}</Title>
      <StarButton onClick={() => setState(!state)}>
        <img src={state ? icon_star_yellow : icon_star_empty} alt={"스타"} />
      </StarButton>
    </TitleView>
  )
}

const TitleView = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
const Title = styled.p`
  color: #555555;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`
const StarButton = styled.button`
  width: 20px;
  height: 20px;
  margin-left: 16px;
  & > img {
    width: 20px;
    height: 20px;
  }
`

export default PageName
