// 메인
import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import styled from "styled-components"
import Layout from "../component/Layout"
import SEO from "../seo"

const Main = (props) => {
  return (
    <Layout>
      <SEO title="메인" />
      <Container></Container>
    </Layout>
  )
}

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`

export default Main
