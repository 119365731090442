import React, { useState, useEffect } from "react"
import styled from "styled-components"
import icon_selector from "images/selector.png"

import Select, { components } from 'react-select'
// import makeAnimated from 'react-select/animated';

// const animatedComponents = makeAnimated();

const MultiComboBox = (props) => {
  
  const { style = {}, placeholder, items, value, setValue, } = props

  // const [ open, setOpen ] = useState(false);
  // const [ index, setIndex ] = useState(0);
  // const [ dataList, setDataList ] = useState([]);

  const onChangeValue = ( v ) => {

    // setIndex( i )
    setValue( v )

    // console.log(777,v)

  }

  // useEffect(() => {

  //   setDataList( dataList.concat( items ) )
  //   setIndex( value > 0 ? dataList.findIndex(row => row.seq == value) : 0)

  // }, [])

  const styles = {
    control: (base, state) => ({
      ...base,
      boxShadow: "none",
      border: state.menuIsOpen ? '1px solid #52935d' : '1px solid #dedede',
      "&:hover": {
        border: '1px solid #52935d',
      },
    }),

    option: base => ({
      ...base,
      width: '100%',
      height: '40px',
      textAlign: 'left',
      borderBottom: '1px solid #dedede',
      fontSize: '14px',
    }),

    menu: (provided, state) => ({
      ...provided,
      padding: 0,
      margin:0,
    }),

    menuList: (provided, state) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
   }),

  };

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={icon_selector} alt={""} style={{width: '24px', height: '24px'}}/>
      </components.DropdownIndicator>
    );
  };

  return (
    <StyledDropdown style={{ ...style }} >
      
      <Select 
        placeholder={<div style={{fontSize:'14px',color:"#55555566"}}>{placeholder}</div>}
        // defaultValue={dataList[0]}
        // options={dataList} 
        defaultValue={value}
        value={value}
        options={items}
        isMulti 
        // components={animatedComponents} 
        components={{ DropdownIndicator, IndicatorSeparator:() => null }}
        onChange={onChangeValue}
        noOptionsMessage={() => null}

        styles={styles}
        theme={theme => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary50: '', // 항목 선택시 
            primary25: '', // 항목 mouse over  
            primary: '', // 선택된 항목 배경 
          },
        })}

      />

    </StyledDropdown>
  )
}

const StyledDropdown = styled.div`
  position: relative;
  width: 500px;
  margin-right: 30px;
`

export default MultiComboBox
