import React from "react"
import { Router } from "@reach/router"
import { useDispatch, useSelector } from "react-redux"
import { setServerErrorPopup } from "../actions/ConfigAction"
import PrivateRoute from "../components/privateRoute"
import "../components/layout.css"
import Page404 from "./404"
import Sample from "../components/page/Sample"
import Root from "../components/page/Root"
import Login from "../components/page/Login"
// import SignUp from "../components/page/SignUp"
// import ChangePw from "../components/page/ChangePw"
import Main from "../components/page/Main"
import ProfitLossStatus from "../components/page/ProfitLossStatus"
import FarmMap from "../components/page/FarmMap"
import FailureReception from "../components/page/FailureReception"
import JoinWaiting from "../components/page/JoinWaiting"
import JoinStatus from "../components/page/JoinStatus"
import LoginHistory from "../components/page/LoginHistory"
import ErrorLog from "../components/page/ErrorLog"

import BasicPopup from "../components/popup/BasicPopup"

const App = () => {
  const dispatch = useDispatch()
  const serverErrorPopup = useSelector((state) => state.ConfigReducer.serverErrorPopup)

  return (
    <>
      <BasicPopup
        open={serverErrorPopup.open}
        onClose={() => dispatch(setServerErrorPopup({ ...serverErrorPopup, open: false }))}
        content={serverErrorPopup.text}
        selectedValue={["확인"]}
      />
      <Router basepath="/app" style={{ width: "100%", height: "100%" }}>
        {/* 샘플 페이지 */}
        <Sample path="/sample" />
        {/* 로그인/회원가입 */}
        <Root path="/" />
        <Login path="/login" />
        {/* <SignUp path="/signup" />
        <ChangePw path="/changePw" /> */}
        {/* 메뉴 */}
        <PrivateRoute component={Main} path="/main" />
        <PrivateRoute component={ProfitLossStatus} path="/profitLossStatus" />
        
        <PrivateRoute component={FarmMap} path="/farmMap" />
        <PrivateRoute component={FailureReception} path="/failureReception" />
        <PrivateRoute component={JoinStatus} path="/joinStatus" />
        <PrivateRoute component={JoinWaiting} path="/joinWaiting" />
        <PrivateRoute component={LoginHistory} path="/loginHistory" />
        <PrivateRoute component={ErrorLog} path="/errorLog" />
        
        {/* 설정 이외의 경로로 들어갈 경우 404 페이지 띄움 */}
        <Page404 path="/*" />
      </Router>
    </>
  )
}

export default App
