import React from "react"
import { navigate } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"

import { TOKEN_ID } from "config"
import SignContainer from "../../containers/SignContainer"

import icon_logo from "images/elefarm.png"
import icon_profile_green from "images/profile_green.png"
import icon_alarm_green from "images/alarm_green.png"
import icon_alarm_gray from "images/alarm_gray.png"
import icon_setting_green from "images/setting_green.png"
import icon_setting_gray from "images/setting_gray.png"
import icon_logout_green from "images/logout_green.png"
import icon_logout_gray from "images/logout_gray.png"

const Header = (props) => {
  
  // const classes = useStyles()
  const { postSignOut } = props

  const menuList = [
    { moduleId: "profitLossStatus", moduleName: "매입/매출" },
    { moduleId: "farmMap", moduleName: "지역별 농장정보" },
    { moduleId: "failureReception", moduleName: "장애접수 및 처리" },
    { moduleId: "joinStatus", moduleName: "월별 가입현황" },
    { moduleId: "joinWaiting", moduleName: "농가 가입대기" },
    { moduleId: "loginHistory", moduleName: "농가별 로그인 이력" },
    { moduleId: "errorLog", moduleName: "에러 로그" },
  ]

  return (
    <Container>
      <LeftBox>
        <Logo src={icon_logo} alt={"로고"} onClick={() => navigate("/app/main")} />
        <TitleView>
          <Version>ELE-FARM</Version>
          <FarmName>관리자</FarmName>
        </TitleView>
        <div style={{ backgroundColor: "#979797", opacity: "16%", width: "1px", height: "71px" }} />
        <MenuView>
          {menuList.map((menuItem, menuIndex) => (
            <MenuItem
              key={menuIndex}
              onClick={() => navigate(`/app/${menuItem.moduleId}`)}
              opacity={window.location.pathname.toLowerCase().includes(menuItem.moduleId.toLowerCase()) ? "100%" : "50%"}
              borderBottom={window.location.pathname.toLowerCase().includes(menuItem.moduleId.toLowerCase()) ? "4px solid #52935d" : "4px solid #fff"}
            >
              {menuItem.moduleName}
            </MenuItem>
          ))}
        </MenuView>
      </LeftBox>
      <RightBox>
        <ProfileButton>
          {/* <img src={icon_profile_green} alt={"프로필"} />
          <p>관리자</p> */}
        </ProfileButton>
        {/* <AlarmButton onClick={() => navigate("/app/alarm")}>
          <img src={icon_alarm_green} alt={"알람"} />
        </AlarmButton>
        <SettingButton onClick={() => {}}>
          <img src={icon_setting_green} alt={"설정"} />
        </SettingButton> */}
        <LogoutButton
          onClick={async() => {

            const result = await postSignOut()

            if (result) {
              navigate("/app/login")
            }

          }}
        >
          <img src={icon_logout_gray} alt={"로그아웃"} />
        </LogoutButton>
      </RightBox>
    </Container>
  )
}

const Container = styled.div`
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 96px;
  padding: 0px 17px;
  border-bottom: 2px solid #e2e2e2;
`
const LeftBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
`
const Logo = styled.img`
  cursor: pointer;
  width: 70px;
  height: 70px;
`
const TitleView = styled.div`
  width: 206px;
  margin-left: 28px;
`
const Version = styled.p`
  opacity: 40%;
  color: #555555;
  font-size: 14px;
`
const FarmName = styled.p`
  color: #555555;
  font-size: 16px;
`
const MenuView = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 94px;
  margin-left: 25px;
`
const MenuItem = styled.button`
  cursor: pointer;
  display: block;
  height: 94px;
  padding: 0 25px;
  border-bottom: ${(props) => props.borderBottom};
  opacity: ${(props) => props.opacity};
  color: #555555;
  font-size: 16px;
`
const RightBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const ProfileButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 30px;
  & > img {
    width: 20px;
    height: 26px;
    margin-right: 14px;
  }
  & > p {
    color: #555555;
    font-size: 18px;
    /* line-height: 31px; */
  }
`
const AlarmButton = styled.button`
  width: 44px;
  height: 40px;
  & > img {
    width: 22px;
    height: 28px;
  }
`
const SettingButton = styled.button`
  width: 48px;
  height: 48px;
  & > img {
    width: 24px;
    height: 24px;
  }
`
const LogoutButton = styled.button`
  width: 48px;
  height: 48px;
  & > img {
    width: 24px;
    height: 24px;
  }
`

Header.propTypes = {}

export default SignContainer(Header)
