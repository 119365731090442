// 기본정보 > 품목 > 매입/매출
import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import styled from "styled-components"

import { dateFormat } from "../../utils/util"

import SEO from "../seo"
import Layout from "../component/Layout"

import AdminContainer from "../../containers/AdminContainer"

import { SkyButton, YellowButton, GrayButton } from "../component/common/Button"
import MultiComboBox from "../component/common/MultiComboBox"
import ComboBox from "../component/common/ComboBox"
import DateBox from "../component/common/DateBox"
import { TableComponent } from "../component/common/Table"

const ProfitLossStatus = (props) => {
  const { 
    
    initDataList, getDataList,
    profitList : dataList, PROFIT_LIST : actionType, 

    sminorComboList,
    getSMinorComboList,

    farmComboList,
    getFarmComboList,
  
  } = props

  // const temp1 = dateFormat(new Date(),'YYYY-01-01')
  // const temp2 = dateFormat(new Date(),'YYYY-MM-DD')

  const temp1 = dateFormat(new Date(), "YYYY0101")
  const temp2 = dateFormat(new Date(), "YYYYMMDD")

  const [dateFr, setDateFr] = useState( temp1 )
  const [dateTo, setDateTo] = useState( temp2 )

  const [regionCodeSeq, setRegionCode] = useState(0)
  const [farmOptions, setFarmOptions] = useState([])

  const [minDate, setMinDate] = useState(new Date()) //날짜 최저일
  const [maxDate, setMaxDate] = useState(new Date()) //날짜 최대일

  useEffect(() => {

    getData()

    return async () => await initDataList( actionType )

  }, [])

  // 달력 날짜 제한 관련
  useEffect(() => {

    // 날짜를 6일 빼기 위해 날짜 객체를 복사합니다
    const originalDate = new Date(dateFr.substr(0, 4) + '-' + dateFr.substr(4, 2) + '-' + dateFr.substr(6, 2));
    const modifiedDate = originalDate.setDate(originalDate.getDate() + 10000);

    setMinDate(new Date(dateFr.substr(0,4)+'-'+dateFr.substr(4,2)+'-'+dateFr.substr(6,2)));
    setMaxDate(modifiedDate);
    // setMaxDate(new Date(calcDate(dateTo, 0).resDate));
    // console.log('dateFr(1) : ', dateFr)
    // console.log('dateTo(1) : ', dateTo)

  }, [dateFr])

  const getData = async () => {

    await getSMinorComboList()
    await getFarmComboList()
  }

  const onClickSearch = async () => {

    const regionCodeList = sminorComboList.filter( row => row.minorSeq === regionCodeSeq );

    const data = {

      dateFr : dateFr.replaceAll('-',''), 
      dateTo : dateTo.replaceAll('-',''),
      regionCode : regionCodeList.length > 0 ? regionCodeList[0].minorName : "",
      farmSeqs : farmOptions.map( row => row.value ) 

    }
    
    await getDataList( actionType, data )

  }

  return (
    <Layout>
      <SEO title="매입/매출" />
      <Container>
        <WhiteBox>
          <SearchBox>
            <div>
              <Text>일자</Text>
              <DateBox stdDate={dateFr} setStdDate={setDateFr} style={{ marginRight: "5px", width: "120px" }} />
              <Text style={{width:'15px'}} >~</Text>
              <DateBox stdDate={dateTo} setStdDate={setDateTo} style={{ width: "120px" }} minDate={minDate} maxDate={maxDate} />
            </div>
            <YellowButton onClick={onClickSearch}>조회</YellowButton>
          </SearchBox>
          <ClassesBox>
            <div>
              <Text>지역</Text>
              <ComboBox
                defaultValue={'선택'}
                hasDefault={true}
                items={sminorComboList.filter(row => row.majorSeq == 1022).map(row => ({ value : row.minorSeq, label : row.minorName }))}
                value={regionCodeSeq}
                setValue={setRegionCode}

                style={{ width: "260px" }} 

              />
              <Text>농장명</Text>
              <MultiComboBox
                placeholder={'멀티선택'}
                items={farmComboList.map(row => ({ value : row.farmSeq, label : row.farmName }))}
                value={farmOptions}
                setValue={setFarmOptions}
              />
            </div>
          </ClassesBox>
          <TableComponent
            headList={[
              { title: "지역", type: "text" },
              { title: "농장명", type: "text" },
              { title: "농장주명", type: "" },
              { title: "대표품목", type: "text" },
              { title: "판매금액", type: "comma" },
              { title: "구매금액", type: "comma" },
              { title: "손익(판매금액-구매금액)", type: "comma" },
            ]}
            bodyList={dataList.map((i) => ({
              regionCode: i.regionCode,
              farmName: i.farmName,
              farmOwner: i.farmOwner,
              itemName: i.itemName,
              salesAmt: i.salesAmt,
              costAmt: i.costAmt,
              profitAmt: (i.salesAmt-i.costAmt),
            }))}
          />
        </WhiteBox>
      </Container>
    </Layout>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`
const WhiteBox = styled.div`
  background-color: #fff;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 5px;
  padding: 28px 32px 32px;
  border: 1px solid #fff;
  border-radius: 9px;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
`
const SearchBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`
const Text = styled.p`
  width: 80px;
  color: #555555;
  font-size: 14px;
`
const ClassesBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 17px;
  & > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`

export default AdminContainer(ProfitLossStatus)
