import { handleActions } from "redux-actions"
import actions from "../actions"

// action type load
const { IS_LOGIN } = actions.SignAction

// default state value
const initialState = {
  isLogin: false,
}

// handle action
export default handleActions(
  {
    [IS_LOGIN]: (state, action) => ({
      ...state,
      isLogin: action.payload,
    }),
  },
  initialState
)
