import * as ConfigAction from "./ConfigAction"
import * as SampleAction from "./SampleAction"
import * as SignAction from "./SignAction"
import * as AdminAction from "./AdminAction"
import * as CommonAction from "./CommonAction"

export default {
  ConfigAction,
  SampleAction,
  SignAction,
  AdminAction,
  CommonAction,
}
