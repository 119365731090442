import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import styled from "styled-components"
import Layout from "../component/Layout"
import SEO from "../seo"
import { YellowButton, SkyButton, GreenButton, GrayButton } from "../component/common/Button"
import CheckBox from "../component/common/CheckBox"
import CircleCheckBox from "../component/common/CircleCheckBox"
import PageName from "../component/common/PageName"
import Radio from "../component/common/Radio"
import Switch from "../component/common/Switch"
import { TableBox, Table, Tr, Thead, Th, Tbody, Td, ActionButton } from "../component/common/Table"
import TableCheckBox from "../component/common/TableCheckBox"

import DateBox from "../component/common/DateBox"
import ComboBox from "../component/common/ComboBox"
import MultiComboBox from "../component/common/MultiComboBox"
import InputBox from "../component/common/InputBox"
import InputYMBox from "../component/common/InputYMBox"
import InputTimeBox from "../component/common/InputTimeBox"
import InputTelBox from "../component/common/InputTelBox"
 
const Sample = () => {
  const [check, setCheck] = useState(false)
  const [like, setLike] = useState(false)
  const [radioIndex, setRadioIndex] = useState(0)
  const [switchOn, setSwitchOn] = useState(false)

  const [stdDate, setStdDate] = useState("20211019")
  const [value, setValue] = useState(0)
  const [inputValue0, setInputValue0] = useState("0103") // 월일 
  const [inputValue1, setInputValue1] = useState("1111") // 시분 
  const [inputValue2, setInputValue2] = useState("") // 핸드폰 번호 
  const [inputValue3, setInputValue3] = useState("") // 핸드폰 번호 
  const [inputValue4, setInputValue4] = useState("") // 전화 번호 
  const [inputValue5, setInputValue5] = useState("") // 이름 
  const [inputValue6, setInputValue6] = useState("") // 수량 
  const [inputValue7, setInputValue7] = useState("") // 금액 

  const items = [
    {value:1,label:'a'},
    {value:2,label:'b선택'},
    {value:3,label:'c'}
  ]

  return (
    <Layout>
      <SEO title="샘플" />
      <Container>
        <div style={{ padding: "0 10px" }}>
          <Title>1. 버튼</Title>
          <YellowButton style={{ marginRight: "10px" }}>버튼</YellowButton>
          <SkyButton style={{ marginRight: "10px" }}>버튼</SkyButton>
          <GreenButton style={{ marginRight: "10px" }}>버튼</GreenButton>
          <GrayButton style={{}}>버튼</GrayButton>
          <hr />
          <Title>2. 체크박스</Title>
          <CheckBox style={{}} text="체크박스" check={check} setCheck={setCheck} />
          <CircleCheckBox style={{}} text="체크박스" check={check} setCheck={setCheck} />

          

          <hr />
          <Title>3. 드롭다운</Title>
          
          <MultiComboBox style={{}} placeholder={"멀티선택"} items={items} value={value} setValue={setValue} />
          <ComboBox style={{}} defaultValue={"선택"} hasDefault={true} items={items} value={value} setValue={setValue} />
          <DateBox style={{}} stdDate={stdDate} setStdDate={setStdDate}/>

          <InputYMBox style={{ }} disabled={false} value={inputValue0} setValue={setInputValue0} />
          <InputTimeBox style={{ }} disabled={false} value={inputValue1} setValue={setInputValue1} />

          <InputBox style={{ }} placeholder={"000 - 00 - 00000"} type="text" disabled={false} mask="### - ## - #####" 
            value={inputValue2} onChange={(e)=>setInputValue2(e.target.value)}  
          />

          <InputBox style={{ }} placeholder={"010 - 0000 - 0000"} type="text" mask="010 - #### - ####" 
            value={inputValue3} onChange={(e)=>setInputValue3(e.target.value)}  
          />

          <InputTelBox style={{ }} value={inputValue4} setValue={setInputValue4} />

          <InputBox style={{ }} placeholder={"이름"} type="text" 
            value={inputValue5} onChange={(e)=>setInputValue5(e.target.value)}  
          />

          <InputBox style={{ }} placeholder={"수량"} type="number" 
            value={inputValue6} onChange={(e)=>setInputValue6(e.target.value)}  
          />

          <InputBox style={{ }} placeholder={"금액"} type="number" useComma={true} maxLength={5}
            value={inputValue7} onChange={(e)=>setInputValue7(e.target.value)}  
          />

          <hr />
          ={stdDate}=
          ={inputValue0}=
          ={inputValue1}=
          ={inputValue2}=
          ={inputValue3}=
          ={inputValue4}=
          ={inputValue5}=
          ={inputValue6}=
          ={inputValue7}=
          <hr />

          <hr />
          <Title>4. 페이지 이름</Title>
          <PageName name="페이지 이름" state={like} setState={() => setLike(!like)} />
          <hr />
          <Title>5. 라디오 버튼</Title>
          <Radio style={{}} text="라디오1" idx={0} check={radioIndex} setCheck={setRadioIndex} />
          <Radio style={{}} text="라디오2" idx={1} check={radioIndex} setCheck={setRadioIndex} />
          <hr />
          <Title>6. 스위치</Title>
          <Switch bool={switchOn} setBool={setSwitchOn} />
          <hr />
          <Title>7. 테이블</Title>
          <TableBox>
            <Table>
              <Thead>
                <Tr className="thead">
                  <Th width={100}>품명</Th>
                  <Th width={100}>품번</Th>
                  <Th width={65}>품종</Th>
                  <Th width={65}>단위</Th>
                  <Th width={90}>품목분류1</Th>
                  <Th width={90}>품목분류2</Th>
                  <Th width={90}>품목분류3</Th>
                  <Th width={80}>표준중량</Th>
                  <Th width={80}>표준당도</Th>
                  <Th width={80}>사용여부</Th>
                  <Th width={100}>비고</Th>
                  <Th width={0}>Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {[1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1].map((i, idx) => (
                  <TableData key={idx} item={i} />
                ))}
              </Tbody>
            </Table>
          </TableBox>

          <hr />
        </div>
      </Container>
    </Layout>
  )
}

const Container = styled.div`
  width: 100%;
  height: 100%;
`
const Title = styled.p`
  font-size: 20px;
`

const TableData = () => {
  return (
    <Tr>
      <Td width={100}>shs0655</Td>
      <Td width={100}>123456</Td>
      <Td width={65}>1</Td>
      <Td width={65}>2</Td>
      <Td width={90}>3</Td>
      <Td width={90}>4</Td>
      <Td width={90}>5</Td>
      <Td width={80}>6</Td>
      <Td width={80}>7</Td>
      <Td width={80}>
        <TableCheckBox check={false} setCheck={() => {}} />
      </Td>
      <Td width={100}>8</Td>
      <Td width={0}>
        <ActionButton color={"#038965cc"} onClick={() => {}}>
          수정
        </ActionButton>
        <ActionButton color={"#fa5b59cc"} onClick={() => {}}>
          삭제
        </ActionButton>
      </Td>
    </Tr>
  )
}

export default Sample
