// 구글 MapsJavascript API - 차건담 2023.08.29
import React, { useState, useEffect } from "react";
import { GoogleMap, LoadScript, Marker, InfoWindow } from "@react-google-maps/api";

const MapComponent = (props) => {
  const { dataList, dataInfo } = props;
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [map, setMap] = useState(null);
  const [zoom, setZoom] = useState( 7 );
  const [selectedCenter, setSelectedCenter] = useState({ lat: 36.401242256216634, lng: 127.71898322664308 });

  const fitBounds = (map) => {
    const bounds = new window.google.maps.LatLngBounds();

    if (dataInfo) {
      const temp = dataList.filter(
        (row) => row.py !== 0 && row.px !== 0 && row.farmSeq === dataInfo.seq
      );

      if (temp.length > 0) {
        console.log("temp:", temp)
        bounds.extend({ lat: temp[0].py, lng: temp[0].px });
        setSelectedMarker(temp[0]);
        setSelectedCenter(temp[0]);

        map.fitBounds(bounds);
        setZoom(zoom < 15 ? 15 : 14.9)
      }
    } else {
      dataList
        .filter((row) => row.py !== 0 && row.px !== 0)
        .map((row) => {
          bounds.extend({ lat: row.py, lng: row.px });
          return row;
        });

      setSelectedMarker(null);

      map.fitBounds(bounds);
    }
  };

  useEffect(() => {
    if (dataList.length > 0 && map) {
      fitBounds(map);
    }
  }, [dataList, dataInfo, map]);

  return (
    <LoadScript
      googleMapsApiKey="AIzaSyAfKY9if3fDt2XSEuMZX3JTm7uBuHX_iMs"
      libraries={["places"]}
    >
      <GoogleMap
        mapContainerStyle={{ width: "100vw", height: "100vh" }}
        //center={{ lat: 36.401242256216634, lng: 127.71898322664308 }}
        center={selectedCenter}
        defaultZoom={zoom}
        zoom={zoom}
        onLoad={(map) => setMap(map)}
      >
        {dataList
          .filter((row) => row.py !== 0 && row.px !== 0)
          .map((row, idx) => (
            <Marker
              key={idx}
              position={{
                lat: row.py,
                lng: row.px,
              }}
              onClick={() => {
                console.log("row:", row)
                setSelectedMarker(row);
              }}
            >
            {selectedMarker && selectedMarker.farmSeq === row.farmSeq && <InfoWindow>
            <div style={{ opacity: 0.75, padding: `12px` }}>
              <div style={{fontWeight:'bold', fontSize:'16px'}} >  
                {row.farmName}
              </div>
              <div> 
                {row.addr} {row.addrDetail} 
              </div>
              <div> 
                (우) {row.zipCode} 
              </div>
              <div> 
                {row.homepageUrl === '' ? '' : <a href={row.homepageUrl} target="_blank" >홈페이지</a>} 
              </div>
            </div>
          </InfoWindow>}
            </Marker>
          ))}
      </GoogleMap>
    </LoadScript>
  );
};

export default function MapBox(props) {
  return <MapComponent {...props} />;
}