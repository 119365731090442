const type = "dev"

const URL = {
  prod: {
    API_URL: "https://api.elefarm.net/admin",
  },
  dev: {
    API_URL: "https://api.elefarm.net/admin",
  },
  local: {
    API_URL: "http://localhost:3002",
  },
}

const TOKEN_ID = "elefarm_admin_token"

module.exports = {
  API_URL: process.env.API_URL || URL[type].API_URL,
  TOKEN_ID
}
