import { handleActions } from "redux-actions"
import actions from "../actions"

// action type load
const { 
  INIT_PROFIT_LIST, 
  SET_PROFIT_LIST 
} = actions.AdminAction

// default state value
const initialState = {
  profitList: [],
}

// handle action
export default handleActions(
  {
    
    [INIT_PROFIT_LIST]: (state, action) => {
      
      state = {
        ...state,
        profitList: [],
      }

      return state

    },

    [SET_PROFIT_LIST]: (state, action) => {
      
      state = {
        ...state,
        profitList: action.payload,
      }

      return state

    },

  },
  initialState
)
