// 기본정보 > 품목 > 장애접수 및 처리
import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import styled from "styled-components"

import { dateFormat, fileDownload } from "../../utils/util"

import SEO from "../seo"
import Layout from "../component/Layout"

import AdminContainer from "../../containers/AdminContainer"

import FailureReceptionPopup from "../popup/FailureReceptionPopup"
import FailureReceptionPopup1 from "../popup/FailureReceptionPopup1"
import FailureReceptionPopup2 from "../popup/FailureReceptionPopup2"

import { SkyButton, YellowButton, GrayButton } from "../component/common/Button"
import MultiComboBox from "../component/common/MultiComboBox"
import ComboBox from "../component/common/ComboBox"
import DateBox from "../component/common/DateBox"
import { TableComponent } from "../component/common/Table"

const statusKindList = [
  { value: 1, label: "요청" },
  { value: 2, label: "접수" },
  { value: 3, label: "처리완료" },
]

const FailureReception = (props) => {
  const {
    initDataList,
    getDataList,
    faultList: dataList,
    FAULT_LIST: actionType,

    sminorComboList,
    getSMinorComboList,

    farmComboList,
    getFarmComboList,
  } = props

  // const temp1 = dateFormat(new Date(), "YYYY-MM-01")
  // const temp2 = dateFormat(new Date(), "YYYY-MM-DD")

  const temp1 = dateFormat(new Date(), "YYYYMM01")
  const temp2 = dateFormat(new Date(), "YYYYMMDD")

  const [dateFr, setDateFr] = useState(temp1)
  const [dateTo, setDateTo] = useState(temp2)

  const [regionCodeSeq, setRegionCode] = useState(0)
  const [farmOptions, setFarmOptions] = useState([])
  const [statusKind, setStatusKind] = useState(0)

  const [isPopup, setIsPopup] = useState(false)

  const [isPopup1, setIsPopup1] = useState(false)
  const [isPopup2, setIsPopup2] = useState(false)

  const [faultSeq, setFaultSeq] = useState(0)

  const [minDate, setMinDate] = useState(new Date()) //날짜 최저일
  const [maxDate, setMaxDate] = useState(new Date()) //날짜 최대일

  const openPopup = async (faultSeq) => {
    setIsPopup(true)
    setFaultSeq(faultSeq)
  }
  const openPopup1 = async (faultSeq) => {
    setIsPopup1(true)
    setFaultSeq(faultSeq)
  }
  const openPopup2 = async (faultSeq) => {
    setIsPopup2(true)
    setFaultSeq(faultSeq)
  }

  const closePopup = async () => {
    setIsPopup(false)
  }
  const closePopup1 = async () => {
    setIsPopup1(false)
    onClickSearch()
  }
  const closePopup2 = async () => {
    setIsPopup2(false)
    onClickSearch()
  }

  useEffect(() => {
    getData()

    return async () => await initDataList(actionType)
  }, [])

    // 달력 날짜 제한 관련
    useEffect(() => {

      // 날짜를 6일 빼기 위해 날짜 객체를 복사합니다
      const originalDate = new Date(dateFr.substr(0, 4) + '-' + dateFr.substr(4, 2) + '-' + dateFr.substr(6, 2));
      const modifiedDate = originalDate.setDate(originalDate.getDate() + 10000);
  
      setMinDate(new Date(dateFr.substr(0,4)+'-'+dateFr.substr(4,2)+'-'+dateFr.substr(6,2)));
      setMaxDate(modifiedDate);
      // setMaxDate(new Date(calcDate(dateTo, 0).resDate));
      // console.log('dateFr(1) : ', dateFr)
      // console.log('dateTo(1) : ', dateTo)
  
    }, [dateFr])

  const getData = async () => {
    await getSMinorComboList()
    await getFarmComboList()
  }

  const onClickSearch = async () => {
    const regionCodeList = sminorComboList.filter((row) => row.minorSeq === regionCodeSeq)

    const data = {
      dateFr: dateFr.replaceAll("-", ""),
      dateTo: dateTo.replaceAll("-", ""),
      regionCode: regionCodeList.length > 0 ? regionCodeList[0].minorName : "",
      farmSeqs: farmOptions.map((row) => row.value),
      count: 9999,
      page: 0,
      statusKind,
    }

    await getDataList(actionType, data)
  }

  return (
    <Layout>
      <SEO title="장애접수 및 처리" />
      <Container>
        <FailureReceptionPopup open={isPopup} onClose={closePopup} faultSeq={faultSeq} />
        <FailureReceptionPopup1 open={isPopup1} onClose={closePopup1} faultSeq={faultSeq} />
        <FailureReceptionPopup2 open={isPopup2} onClose={closePopup2} faultSeq={faultSeq} />

        <WhiteBox>
          <SearchBox>
            <div>
              <Text>접수일</Text>
              <DateBox stdDate={dateFr} setStdDate={setDateFr} style={{ marginRight: "5px", width: "120px" }} />
              <Text style={{ width: "15px" }}>~</Text>
              <DateBox stdDate={dateTo} setStdDate={setDateTo} style={{ width: "120px" }} minDate={minDate} maxDate={maxDate} />
            </div>
            <YellowButton onClick={onClickSearch}>조회</YellowButton>
          </SearchBox>
          <ClassesBox>
            <div>
              <Text>지역</Text>
              <ComboBox
                defaultValue={"선택"}
                hasDefault={true}
                items={sminorComboList.filter((row) => row.majorSeq == 1022).map((row) => ({ value: row.minorSeq, label: row.minorName }))}
                value={regionCodeSeq}
                setValue={setRegionCode}
                style={{ width: "260px" }}
              />
              <Text>농장명</Text>
              <MultiComboBox
                placeholder={"멀티선택"}
                items={farmComboList.map((row) => ({ value: row.farmSeq, label: row.farmName }))}
                value={farmOptions}
                setValue={setFarmOptions}
              />
              <Text>처리상태</Text>
              <ComboBox
                defaultValue={"선택"}
                hasDefault={true}
                items={statusKindList}
                value={statusKind}
                setValue={setStatusKind}
                style={{ width: "260px" }}
              />
            </div>
          </ClassesBox>
          <TableComponent
            headList={[
              { title: "접수일", type: "date" },
              { title: "지역", type: "text" },
              { title: "농장명", type: "text" },
              { title: "농장주명", type: "" },
              { title: "전화번호", type: "" },
              { title: "휴대폰번호", type: "" },

              { title: "처리상태", type: "" },
              { title: "장애내용", type: "text", width: "300px" },
              { title: "처리내용", type: "" },
              { title: "처리일", type: "date" },
              { title: "첨부파일", type: "" },
            ]}
            bodyList={dataList.map((i) => ({
              receiptDate: i.receiptDate,
              regionCode: i.regionCode,
              farmName: i.farmName,
              farmOwner: i.farmOwner,
              teleNo: i.teleNo,
              cellNo: i.cellNo,

              action1: (
                <span>
                  {/* {i.statusKind == 1 ? <a href="#" onClick={() => {}}>요청</a>:'요청'}{' > '} */}
                  {i.statusKind == 1 ? (
                    <a href="#" onClick={() => openPopup1(i.faultSeq)}>
                      요청
                    </a>
                  ) : (
                    "요청"
                  )}
                  {" > "}
                  {i.statusKind == 2 ? (
                    <a href="#" onClick={() => openPopup2(i.faultSeq)}>
                      접수
                    </a>
                  ) : (
                    "접수"
                  )}
                  {" > "}
                  {i.statusKind == 3 ? <a href="#">처리완료</a> : "처리완료"}
                </span>
              ),
              // '요청 > 접수 > 처리중 > 처리완료',

              faultContent: i.faultContent,
              action2: (
                <a href="#" onClick={() => openPopup(i.faultSeq)}>
                  내용보기
                </a>
              ),
              processDate: i.processDate,
              // fileUri: i.fileUri,
              action3:
                i.fileUri > "" ? (
                  <a href="#" onClick={() => fileDownload(i.fileUri, i.fileName)}>
                    다운로드
                  </a>
                ) : (
                  ""
                ),
            }))}
          />
        </WhiteBox>
      </Container>
    </Layout>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`
const WhiteBox = styled.div`
  background-color: #fff;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 5px;
  padding: 28px 32px 32px;
  border: 1px solid #fff;
  border-radius: 9px;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
`
const SearchBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`
const Text = styled.p`
  width: 80px;
  color: #555555;
  font-size: 14px;
`
const ClassesBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 17px;
  & > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`

export default AdminContainer(FailureReception)
