// 기본정보 > 품목 > 농가 가입대기
import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import styled from "styled-components"

import { dateFormat } from "../../utils/util"

import SEO from "../seo"
import Layout from "../component/Layout"

import AdminContainer from "../../containers/AdminContainer"

import { SkyButton, YellowButton, GrayButton, GreenButton } from "../component/common/Button"
import MultiComboBox from "../component/common/MultiComboBox"
import ComboBox from "../component/common/ComboBox"
import DateBox from "../component/common/DateBox"
import InputBox from "../component/common/InputBox"
import TableCheckBox from "../component/common/TableCheckBox"
import { TableComponent } from "../component/common/Table"

import BasicPopup from "../popup/BasicPopup"

const applyStatusList = [
  { value: 1, label: "대기" },
  { value: 2, label: "승인" },
  { value: 3, label: "거절" },
  { value: 4, label: "탈퇴" },
]

const JoinWaiting = (props) => {
  const {
    initDataList,
    getDataList,
    saveDataList,
    applyList: dataList,
    APPLY_LIST: actionType,
    AGR_APPLY_LIST,
    REJ_APPLY_LIST,
    OUT_APPLY_LIST,
    setApplyList,
  } = props

  // const temp1 = dateFormat(new Date(), "YYYY-MM-01")
  // const temp2 = dateFormat(new Date(), "YYYY-MM-DD")

  const temp1 = dateFormat(new Date(), "YYYYMM01")
  const temp2 = dateFormat(new Date(), "YYYYMMDD")

  const [dateFr, setDateFr] = useState(temp1)
  const [dateTo, setDateTo] = useState(temp2)

  const [farmName, setFarmName] = useState("")
  const [applyStatus, setApplyStatus] = useState(0)

  const [checks, setChecks] = useState([])

  const [popup, setPopup] = useState({ open: false, text: "" })

  const [minDate, setMinDate] = useState(new Date()) //날짜 최저일
  const [maxDate, setMaxDate] = useState(new Date()) //날짜 최대일

  useEffect(() => {
    getData()

    return async () => await initDataList(actionType)
  }, [])

  // 달력 날짜 제한 관련
  useEffect(() => {
    
    // 날짜를 6일 빼기 위해 날짜 객체를 복사합니다
    const originalDate = new Date(dateFr.substr(0, 4) + '-' + dateFr.substr(4, 2) + '-' + dateFr.substr(6, 2));
    const modifiedDate = originalDate.setDate(originalDate.getDate() + 10000);

    setMinDate(new Date(dateFr.substr(0,4)+'-'+dateFr.substr(4,2)+'-'+dateFr.substr(6,2)));
    setMaxDate(modifiedDate);
    // setMaxDate(new Date(calcDate(dateTo, 0).resDate));
    // console.log('dateFr(1) : ', dateFr)
    // console.log('dateTo(1) : ', dateTo)

  }, [dateFr])

  const getData = async () => {}

  const saveData = async (type) => {
    const data = {
      applySeqs: dataList.filter((row) => row.isCheck == "1").map((row) => row.applySeq),
    }
    // applyStatus: 0(전체), 1(대기), 2(승인), 3(거절), 4(탈퇴)
    const chkData = {
      applyStatus: dataList.filter((row) => row.isCheck == "1").map((row) => row.applyStatus),
    }

    if (data.applySeqs.length < 1) return setPopup({ open: true, text: "회원을 선택해주세요." })
    else if (type == 3 && chkData.applyStatus != 2) return setPopup({ open: true, text: "승인된 회원만 탈퇴가 가능합니다." })
    else if (type == 2 && chkData.applyStatus != 1) return setPopup({ open: true, text: "대기상태가 아닙니다." })

    if (type == 1 && (await saveDataList(AGR_APPLY_LIST, data))) {
      // 승인
      alert("가입승인 되었습니다.")
      onClickSearch()
    } else if (type == 2 && (await saveDataList(REJ_APPLY_LIST, data))) {
      // 거절

      alert("가입거절 되었습니다.")
      onClickSearch()
    } else if (type == 3 && (await saveDataList(OUT_APPLY_LIST, data))) {
      // 탈퇴

      alert("탈퇴 되었습니다.")
      onClickSearch()
    } // end if
  }

  const onClickSearch = async () => {
    const data = {
      dateFr: dateFr.replaceAll("-", ""),
      dateTo: dateTo.replaceAll("-", ""),
      farmName,
      applyStatus,
    }

    await getDataList(actionType, data)
  }

  const onClickCheckBox = async (applySeq, isCheck) => {
    const data = dataList.map((row) => (row.applySeq == applySeq ? { ...row, isCheck } : row))
    await setApplyList(data)
  }

  return (
    <Layout>
      <SEO title="농가가입대기" />
      <Container>
        <BasicPopup open={popup.open} onClose={() => setPopup({ open: false, text: "" })} content={popup.text} selectedValue={["확인"]} />
        <WhiteBox>
          <SearchBox>
            <div>
              <Text>신청일</Text>
              <DateBox stdDate={dateFr} setStdDate={setDateFr} style={{ marginRight: "5px", width: "120px" }} />
              <Text style={{ width: "15px" }}>~</Text>
              <DateBox stdDate={dateTo} setStdDate={setDateTo} style={{ width: "120px" }} minDate={minDate} maxDate={maxDate}/>
            </div>
            <YellowButton onClick={onClickSearch}>조회</YellowButton>
          </SearchBox>
          <ClassesBox>
            <div>
              <Text>농장명</Text>
              <InputBox
                style={{ width: "260px" }}
                placeholder={""}
                type="text"
                disabled={false}
                value={farmName}
                onChange={(e) => setFarmName(e.target.value)}
              />
              <Text>가입상태</Text>
              <ComboBox
                defaultValue={"선택"}
                hasDefault={true}
                items={applyStatusList}
                value={applyStatus}
                setValue={setApplyStatus}
                style={{ width: "260px" }}
              />
            </div>
            <div>
              <GreenButton onClick={() => saveData(1)} style={{ marginRight: "10px" }}>
                가입승인
              </GreenButton>
              <GrayButton onClick={() => saveData(2)} style={{ marginRight: "10px" }}>
                가입거절
              </GrayButton>
              <GrayButton onClick={() => saveData(3)}>탈퇴</GrayButton>
            </div>
          </ClassesBox>
          <TableComponent
            headList={[
              { title: "선택", type: "checkbox" },
              { title: "농장코드", type: ""},
              { title: "가입일", type: "date" },
              { title: "가입상태", type: "text" },
              { title: "지역", type: "text" },
              { title: "농장명", type: "text" },
              { title: "농장주명", type: "text" },
              { title: "전화번호", type: "" },
              { title: "핸드폰번호", type: "" },
              { title: "분야", type: "" },
              { title: "대표품목", type: "text" },
              // { title: "농장로고", type: "" },
            ]}
            bodyList={dataList.map((i) => ({
              //seq: i.applySeq,
              select: <TableCheckBox check={i.isCheck == "1"} setCheck={() => onClickCheckBox(i.applySeq, i.isCheck == "1" ? "0" : "1")} />,
              farmSeq: i.farmSeq,
              applyDate: i.applyDate,
              applyStatusName: i.applyStatus > 0 ? applyStatusList.filter((row) => row.value == i.applyStatus)[0].label : "",
              regionCode: i.regionCode,
              farmName: i.farmName,
              farmOwner: i.farmOwner,
              cellNo: i.cellNo,
              teleNo: i.teleNo,
              bizpartName: i.bizpartName,
              itemName: i.itemName,
              // photoUri: <img src={i.photoUri} style={{width:'150px', height:'150px'}} />,
            }))}
          />
        </WhiteBox>
      </Container>
    </Layout>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`
const WhiteBox = styled.div`
  background-color: #fff;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 5px;
  padding: 28px 32px 32px;
  border: 1px solid #fff;
  border-radius: 9px;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
`
const SearchBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`
const Text = styled.p`
  width: 80px;
  color: #555555;
  font-size: 14px;
`
const ClassesBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 17px;
  & > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`

export default AdminContainer(JoinWaiting)
