import { createAction } from "redux-actions"
import CommonApis from "../apis/CommonApis"

/* action type */
export const SET_DATA_LIST = "@elefarm/admin/SET_DATA_LIST"
export const SET_DATA_INFINITE_LIST = "@elefarm/admin/SET_DATA_INFINITE_LIST"

/* action function */
export const setDataList = createAction(SET_DATA_LIST)
export const setDataInfiniteList = createAction(SET_DATA_INFINITE_LIST)

export const initDataList = (actionType) => async (dispatch) => {
  let data = {}
  data[actionType.id] = actionType.initData

  dispatch(setDataList(data))

  return true
}

export const getDataList = (actionType, param) => async (dispatch) => {
  const result = await CommonApis.getApi(actionType.uri, param)

  if (result.code === 0) {
    let data = {}
    data[actionType.id] = result.data.dataList

    dispatch(setDataList(data))

    return true
  } else return false
}

export const getDataInfiniteList = (actionType, param) => async (dispatch) => {
  const result = await CommonApis.getApi(actionType.uri, param)

  if (result.code === 0) {
    let data = {}
    data[actionType.id] = result.data.dataList

    dispatch(setDataInfiniteList(data))

    return true
  } else return false
}

export const saveDataList = (actionType, param) => async (dispatch) => {
  const result = await CommonApis.postApi(actionType.uri, param)

  if (result.code === 0) {
    return true
  } else return false
}

/* action type */
export const SET_SMINOR_COMBO_LIST = "@elefarm/admin/SET_SMINOR_COMBO_LIST"
export const SET_UMINOR_COMBO_LIST = "@elefarm/admin/SET_UMINOR_COMBO_LIST"
export const SET_FARM_COMBO_LIST = "@elefarm/admin/SET_FARM_COMBO_LIST"

/* action function */
export const setSMinorComboList = createAction(SET_SMINOR_COMBO_LIST)
export const setUMinorComboList = createAction(SET_UMINOR_COMBO_LIST)
export const setFarmComboList = createAction(SET_FARM_COMBO_LIST)

/* action api function */
export const getSMinorComboList = () => async (dispatch) => {
  // 공통코드

  const result = await CommonApis.getComboList({ queryKind: "SMinor" })

  if (result.code === 0) {
    dispatch(setSMinorComboList(result.data.dataList))

    return true
  } else return false
}

export const getUMinorComboList = () => async (dispatch) => {
  // 사용자코드

  const result = await CommonApis.getComboList({ queryKind: "UMinor" })

  if (result.code === 0) {
    dispatch(setUMinorComboList(result.data.dataList))

    return true
  } else return false
}

export const getFarmComboList = () => async (dispatch) => {
  // 농장

  const result = await CommonApis.getComboList({ queryKind: "Farm" })

  if (result.code === 0) {
    dispatch(setFarmComboList(result.data.dataList))

    return true
  } else return false
}

// 품목
// 거래처
// 작업장
// 구역
// 프로그램코드
