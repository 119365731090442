// 품목 추가/수정 팝업
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import moment from "moment"

import { makeStyles } from "@material-ui/styles"
import { Dialog } from "@material-ui/core"

import { dateFormat } from "../../utils/util"

import AdminContainer from "../../containers/AdminContainer"

import DateBox from "../component/common/DateBox"

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    margin: "0 auto",
    "& .MuiDialog-paperFullWidth": {
      width: "100%",
    },
    "& .MuiPaper-root": {
      backgroundColor: "#fff",
      maxWidth: "615px",
    },
    "& .MuiDialog-paper": {
      margin: "0px",
    },
  },
}))

const FailureReceptionPopup2 = (props) => {

  const classes = useStyles()
  
  const { 
    
    onClose, open, 
    
    initDataList, getDataList, saveDataList,
    faultInfo : dataInfo, FAULT_INFO : actionType, DONE_FAULT_INFO,
    faultSeq 
  
  } = props

  
  const temp1 = dateFormat(new Date(),'YYYY-MM-DD')

  const [date, setDate] = useState( temp1 )
  const [processContent, setProcessContent] = useState("")
  
  useEffect(() => {

    if( open ) getData( faultSeq )

    return async () => await initDataList( actionType )

  }, [open])

  const getData = async ( faultSeq ) => {

    const data = {

      faultSeq

    }

    await getDataList( actionType, data )

  }

  const saveData = async () => {

    const data = {

      faultSeq, 
      processDate : date.replaceAll('-',''),
      processContent,

    }

    await saveDataList( DONE_FAULT_INFO, data )

    onClose()

  }

  return (
    <Dialog
      className={classes.container}
      style={{ position: "absolute", width: "100%", height: "100%", zIndex: 100 }}
      onClose={() => onClose()}
      open={open}
      fullWidth={true}
    >
      {/* <AlertPopup bg={false} open={alertPopup.open} onClose={() => setAlertPopup({ open: false, text: alertPopup.text })} content={alertPopup.text} /> */}
      <div
        style={{
          backgroundColor: "#fff",
          overflowY: "hidden",
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "800px",
          padding: "23px 51px 44px 40px",
        }}
      >
        <Title>장애처리</Title>
        <InputArea>
          <FlexBox>
            <Label>처리일</Label>
            <DateBox stdDate={date} setStdDate={setDate} style={{ width: "120px" }} disabled={true} />
          </FlexBox>
          <FlexBox alignItems={"flex-start"}>
            <Label>처리내용</Label>
            <TextArea value={processContent || dataInfo.processContent} onChange={(e) => setProcessContent(e.target.value)}/>
          </FlexBox> 
        </InputArea>
        <ButtonBox>
          <Button
            bgColor={"#fff"}
            hover={"rgba(174, 174, 174, 0.22)"}
            border={"1px solid #c6c6c6"}
            color={"#555555cc"}
            onClick={() => onClose()}
          >
            취소
          </Button>
          <Button bgColor={"#52935d"} hover={"#3e7347"} border={"none"} color={"#fff"} onClick={()=>saveData()}>
            저장
          </Button>
        </ButtonBox>
      </div>
    </Dialog>
  )
}

const Title = styled.p`
  opacity: 80%;
  margin-bottom: 23px;
  color: #555555;
  font-size: 15px;
  font-weight: 700;
  line-height: 32px;
`
const InputArea = styled.div`
  flex: 1;
`
const FlexBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: ${(props) => props.alignItems || "center"};
  margin-bottom: 14px;
`
const Label = styled.p`
  opacity: 80%;
  width: 102px;
  color: #555555;
  font-size: 14px;
  line-height: 34px;
`
const Input = styled.input`
  width: ${(props) => props.width || 300}px;
  height: 40px;
`
const TextArea = styled.textarea`
  flex: 1;
  height: 550px;
  resize: none;
`
const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
const Button = styled.button`
  background-color: ${(props) => props.bgColor};
  width: 147px;
  height: 40px;
  border: ${(props) => props.border};
  border-radius: 3px;
  color: ${(props) => props.color};
  font-size: 14px;

  &:last-child {
    margin-left: 14px;
  }

  &:hover {
    background-color: ${(props) => props.hover};
  }

  @media print {
    display: none;
  }
`

FailureReceptionPopup2.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}

export default AdminContainer(FailureReceptionPopup2)
