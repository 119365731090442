import querystring from "querystring"
import api from "../utils/api"

export default {
  
  getApi : async ( uri, data, options = {}) => {

    Object.keys(data || {}).forEach((key) => {

      const _ = data[key]

      if ( Array.isArray( _ ) ) data[key] = JSON.stringify( data[key] )

    });

    let queryString = querystring.stringify(data);

    const result = await api.get(`${uri}?${queryString}`, { options: options })
  
    return result.data
  
  },

  postApi : async ( uri, data) => {

    const result = await api.post( uri, { body: data })

    return result.data

  },

  getComboList : async (data) => {
  
    const result = await api.get(`/system/combo/list?queryKind=${data.queryKind}`)
    
    return result.data
  
  },

}
