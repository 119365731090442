// 월별가입현황
import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import styled from "styled-components"
import { Bar } from "react-chartjs-2";

import { dateFormat } from "../../utils/util"

import SEO from "../seo"
import Layout from "../component/Layout"

import AdminContainer from "../../containers/AdminContainer"

import { SkyButton, YellowButton, GrayButton, GreenButton } from "../component/common/Button"
import MultiComboBox from "../component/common/MultiComboBox"
import ComboBox from "../component/common/ComboBox"
import DateBox from "../component/common/DateBox"
import InputBox from "../component/common/InputBox"
import TableCheckBox from "../component/common/TableCheckBox"
import { TableComponent } from "../component/common/Table"

const initDataChart = {
  labels: [
    "1월",
    "2월",
    "3월",
    "4월",
    "5월",
    "6월",
    "7월",
    "8월",
    "9월",
    "10월",
    "11월",
    "12월"
  ],
  datasets: [
    {
      label: "My First dataset",
      backgroundColor: "#52935d",
      borderColor: "#52935d",
      borderWidth: 1,
      //stack: 1,
      hoverBackgroundColor: "rgba(255,99,132,0.4)",
      hoverBorderColor: "rgba(255,99,132,1)",
      data: []
    },

    {
      label: "My second dataset",
      backgroundColor: "#edfcf0",
      borderColor: "#52935d",
      borderWidth: 1,
      //stack: 1,
      hoverBackgroundColor: "rgba(255,99,132,0.2)",
      hoverBorderColor: "rgba(255,99,132,1)",
      data: []
    }
  ]
};

const options = {
  responsive: true,
  legend: {
    display: false
  },
  type: "bar"
};

const JoinStatus = (props) => {
  const { 
    
    initDataList, getDataList, saveDataList, 
    joinList : dataList, JOIN_LIST : actionType, 
    joinSubList : dataSubList, JOIN_SUB_LIST : actionSubType, 

  } = props

  const temp1 = dateFormat(new Date(),'YYYY')

  const [stdYear, setStdYear] = useState(temp1)
  const [dataChart, setDataChart] = useState(initDataChart)

  useEffect(() => {

    getData()

  }, [])

  useEffect(() => {

    let temp = dataChart
    
    let obj1List = [], obj2List = []

    let obj = dataList.filter( row => row.isSum == '1' )[0]
    
    for(var k in obj) {
      
      if ( k.includes('monthIn') ) obj1List.push( obj[k] )
      else if ( k.includes('monthOut') ) obj2List.push( obj[k] )

    }

    temp.datasets[0].data = obj1List
    temp.datasets[1].data = obj2List

    console.log(123,temp)

    setDataChart( temp )

  }, [dataList])

  const getData = async () => {
  }

  const onClickSearch = async () => {

    const data = { stdYear }
    
    await getDataList( actionType, data )

  }
  
  const onClickRegionCode = async ( row ) => {

    const data = { regionCode : row.regionCode }
    
    await getDataList( actionSubType, data )

  }

  return (
    <Layout>
      <SEO title="월별가입현황" />
      <Container>
        <WhiteBox>
          <SearchBox>
            <div>
              <Text>가입년도</Text>
              <InputBox style={{ width: "120px" }} 
                placeholder={""} 
                type="number"
                mask={'####'}
                maxLength="4"
                useComma={false}
                disabled={false}
                value={stdYear} 
                onChange={(e)=>setStdYear(e.target.value)} 
              />
            </div>
            <YellowButton onClick={onClickSearch}>조회</YellowButton>
          </SearchBox>
          <TableComponent 
            headList={[
              { title: "지역", type: "text" },
              { title: "1월", type: "" },
              { title: "2월", type: "" },
              { title: "3월", type: "" },
              { title: "4월", type: "" },
              { title: "5월", type: "" },
              { title: "6월", type: "" },
              { title: "7월", type: "" },
              { title: "8월", type: "" },
              { title: "9월", type: "" },
              { title: "10월", type: "" },
              { title: "11월", type: "" },
              { title: "12월", type: "" },
            ]}
            bodyList={dataList.map((i) => ({
              regionCode: i.regionCode,
              month01: (i.monthIn01 == 0 ? '-' : i.monthIn01) +'/'+(i.monthOut01 == 0 ? '-' : i.monthOut01),
              month02: (i.monthIn02 == 0 ? '-' : i.monthIn02) +'/'+(i.monthOut02 == 0 ? '-' : i.monthOut02),
              month03: (i.monthIn03 == 0 ? '-' : i.monthIn03) +'/'+(i.monthOut03 == 0 ? '-' : i.monthOut03),
              month04: (i.monthIn04 == 0 ? '-' : i.monthIn04) +'/'+(i.monthOut04 == 0 ? '-' : i.monthOut04),
              month05: (i.monthIn05 == 0 ? '-' : i.monthIn05) +'/'+(i.monthOut05 == 0 ? '-' : i.monthOut05),
              month06: (i.monthIn06 == 0 ? '-' : i.monthIn06) +'/'+(i.monthOut06 == 0 ? '-' : i.monthOut06),
              month07: (i.monthIn07 == 0 ? '-' : i.monthIn07) +'/'+(i.monthOut07 == 0 ? '-' : i.monthOut07),
              month08: (i.monthIn08 == 0 ? '-' : i.monthIn08) +'/'+(i.monthOut08 == 0 ? '-' : i.monthOut08),
              month09: (i.monthIn09 == 0 ? '-' : i.monthIn09) +'/'+(i.monthOut09 == 0 ? '-' : i.monthOut09),
              month10: (i.monthIn10 == 0 ? '-' : i.monthIn10) +'/'+(i.monthOut10 == 0 ? '-' : i.monthOut10),
              month11: (i.monthIn11 == 0 ? '-' : i.monthIn11) +'/'+(i.monthOut11 == 0 ? '-' : i.monthOut11),
              month12: (i.monthIn12 == 0 ? '-' : i.monthIn12) +'/'+(i.monthOut12 == 0 ? '-' : i.monthOut12),
              
            }))}
            onClick={(row)=>onClickRegionCode(row)}
          />
        </WhiteBox>

        <WhiteBoxBottom style={{paddingTop:'0',height:'200px'}} >
          <FlexBox style={{alignItems:'flex-start'}}>
            <span style={{width:'50%', marginRight:'14px', marginTop: '14px'}}>
              <Bar
                data={dataChart}
                width={null}
                height={'55px'}
                options={options}
              />
            </span>
            <span style={{width:'50%'}}>
              <TableComponent
                style={{height: dataSubList.length> 2 ? '143px' : '100%'}}
                headList={[
                  { title: "가입일", type: "date" },
                  { title: "지역", type: "text" },
                  { title: "농장명", type: "text" },
                  { title: "농장주명", type: "text" },
                  { title: "분야", type: "text" },
                  { title: "대표품목", type: "text" },
                ]}
                bodyList={dataSubList.map((i) => ({
                  joinDate: i.joinDate,
                  regionCode: i.regionCode,
                  farmName: i.farmName,
                  farmOwner: i.farmOwner,
                  bizPartName: i.bizPartName,
                  itemName: i.itemName,
                }))}
              />
            </span>
          </FlexBox>
        </WhiteBoxBottom>
      </Container>
    </Layout>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`
const FlexBox = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction || "row"};
  justify-content: ${(props) => props.justifyContent || "flex-start"};
  align-items: ${(props) => props.alignItems || "center"};
`
const WhiteBox = styled.div`
  background-color: #fff;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  flex:1;
  margin-top: 5px;
  padding: 28px 32px 32px;
  border: 1px solid #fff;
  border-radius: 9px;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
`

const WhiteBoxBottom = styled.div`
  background-color: #fff;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  padding: 28px 32px 32px;
  border: 1px solid #fff;
  border-radius: 9px;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
`

const SearchBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`
const Text = styled.p`
  width: 80px;
  color: #555555;
  font-size: 14px;
`
const ClassesBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 17px;
  & > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`

export default AdminContainer(JoinStatus)
