// 기본정보 > 품목 > 농가별 로그인 이력
import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import styled from "styled-components"

import { dateFormat } from "../../utils/util"

import SEO from "../seo"
import Layout from "../component/Layout"

import AdminContainer from "../../containers/AdminContainer"

import { SkyButton, YellowButton, GrayButton, GreenButton } from "../component/common/Button"
import MultiComboBox from "../component/common/MultiComboBox"
import ComboBox from "../component/common/ComboBox"
import DateBox from "../component/common/DateBox"
import InputBox from "../component/common/InputBox"
import TableCheckBox from "../component/common/TableCheckBox"
import { TableComponent } from "../component/common/Table"

const loginKindList = [
  { value: 1, label: "웹" },
  { value: 2, label: "모바일" },
]

const LoginHistory = (props) => {
  const { initDataList, getDataInfiniteList, saveDataList, loginList: dataList, LOGIN_LIST: actionType } = props

  // const temp1 = dateFormat(new Date(), "YYYY-MM-01")
  // const temp2 = dateFormat(new Date(), "YYYY-MM-DD")

  const temp1 = dateFormat(new Date(), "YYYYMM01")
  const temp2 = dateFormat(new Date(), "YYYYMMDD")

  const [dateFr, setDateFr] = useState(temp1)
  const [dateTo, setDateTo] = useState(temp2)

  const [farmName, setFarmName] = useState("")
  const [loginKind, setLoginKind] = useState(0)
  const [page, setPage] = useState(0)
  const count = 50

  const [minDate, setMinDate] = useState(new Date()) //날짜 최저일
  const [maxDate, setMaxDate] = useState(new Date()) //날짜 최대일

  useEffect(() => {
    return async () => await initDataList(actionType)
  }, [])

  useEffect(() => {
    if (page != 0) getData()
  }, [page])

  const getData = async () => {
    const data = {
      dateFr: dateFr.replaceAll("-", ""),
      dateTo: dateTo.replaceAll("-", ""),
      farmName,
      loginKind,
      page,
      count,
    }

    await getDataInfiniteList(actionType, data)
  }

  // 달력 날짜 제한 관련
  useEffect(() => {

    // 날짜를 6일 빼기 위해 날짜 객체를 복사합니다
    const originalDate = new Date(dateFr.substr(0, 4) + '-' + dateFr.substr(4, 2) + '-' + dateFr.substr(6, 2));
    const modifiedDate = originalDate.setDate(originalDate.getDate() + 10000);

    setMinDate(new Date(dateFr.substr(0,4)+'-'+dateFr.substr(4,2)+'-'+dateFr.substr(6,2)));
    setMaxDate(modifiedDate);
    // setMaxDate(new Date(calcDate(dateTo, 0).resDate));
    // console.log('dateFr(1) : ', dateFr)
    // console.log('dateTo(1) : ', dateTo)

  }, [dateFr])

  const onClickSearch = async () => {
    const data = {
      dateFr: dateFr.replaceAll("-", ""),
      dateTo: dateTo.replaceAll("-", ""),
      farmName,
      loginKind,
      page: 0,
      count,
    }
    setPage(0)
    await initDataList(actionType)
    await getDataInfiniteList(actionType, data)
  }
  console.log(dataList)
  return (
    <Layout>
      <SEO title="농가별로그인이력" />
      <Container>
        <WhiteBox>
          <SearchBox>
            <div>
              <Text>로그인일</Text>
              <DateBox stdDate={dateFr} setStdDate={setDateFr} style={{ marginRight: "5px", width: "120px" }} />
              <Text style={{ width: "15px" }}>~</Text>
              <DateBox stdDate={dateTo} setStdDate={setDateTo} style={{ width: "120px" }} minDate={minDate} maxDate={maxDate} />
            </div>
            <YellowButton onClick={onClickSearch}>조회</YellowButton>
          </SearchBox>
          <ClassesBox>
            <div>
              <Text>농장명</Text>
              <InputBox
                style={{ width: "260px" }}
                placeholder={""}
                type="text"
                disabled={false}
                value={farmName}
                onChange={(e) => setFarmName(e.target.value)}
              />
              <Text>로그인유형</Text>
              <ComboBox
                defaultValue={"선택"}
                hasDefault={true}
                items={loginKindList}
                value={loginKind}
                setValue={setLoginKind}
                style={{ width: "260px" }}
              />
            </div>
          </ClassesBox>
          <TableComponent
            headList={[
              { title: "No.", type: "" },
              { title: "로그인일시", type: "" },
              { title: "농장명", type: "text" },
              { title: "이름", type: "" },
              { title: "아이디", type: "" },
              { title: "로그인유형", type: "" },
            ]}
            bodyList={dataList.map((i, idx) => ({
              no: idx + 1,
              loginDateTime: i.loginDateTime,
              farmName: i.farmName,
              userName: i.userName,
              userId: i.userId,
              loginKindName: i.loginKind > 0 ? loginKindList.filter((row) => row.value == i.loginKind)[0].label : "",
            }))}
            onLoad={() => {
              setPage((prev) => prev + 1)
            }}
          />
        </WhiteBox>
      </Container>
    </Layout>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`
const WhiteBox = styled.div`
  background-color: #fff;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 5px;
  padding: 28px 32px 32px;
  border: 1px solid #fff;
  border-radius: 9px;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
`
const SearchBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`
const Text = styled.p`
  width: 80px;
  color: #555555;
  font-size: 14px;
`
const ClassesBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 17px;
  & > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`

export default AdminContainer(LoginHistory)
