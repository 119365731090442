import { createAction } from "redux-actions"

/* action type */
export const PROFIT_LIST = { uri : '/info/farm/purchaseSales/list', id : 'profitList', initData : [] }
export const FAULT_LIST = { uri : '/info/fault/receipt/list', id : 'faultList', initData : [] }
export const FAULT_INFO = { uri : '/info/fault/receiptConfirm/info', id : 'faultInfo', initData : {} }

export const RECP_FAULT_INFO = { uri : '/info/fault/receipt/mod', id : '', initData : {} }
export const DONE_FAULT_INFO = { uri : '/info/fault/process/mod', id : '', initData : {} }

export const FARM_LIST = { uri : '/info/farm/list', id : 'farmList', initData : [] }

export const APPLY_LIST = { uri : '/info/farm/join/waiting/list', id : 'applyList', initData : [] }

export const AGR_APPLY_LIST = { uri : '/info/farm/join/agree/mod', id : '', initData : {} }
export const REJ_APPLY_LIST = { uri : '/info/farm/join/reject/mod', id : '', initData : {} }
export const OUT_APPLY_LIST = { uri : '/info/farm/join/withdraw/mod', id : '', initData : {} }

export const LOGIN_LIST = { uri : '/info/farm/login/record/list', id : 'loginList', initData : [] }
export const ERROR_LIST = { uri : '/info/farm/error/list', id : 'errorList', initData : [] }
export const JOIN_LIST = { uri : '/info/farm/join/secession/list', id : 'joinList', initData : [] }
export const JOIN_SUB_LIST = { uri : '/info/farm/join/list', id : 'joinSubList', initData : [] }

export const SET_APPLY_LIST = "@elefarm/admin/SET_APPLY_LIST"

export const setApplyList = createAction(SET_APPLY_LIST)

export const checkApplyList = ( param ) => async (dispatch) => {

	dispatch(setApplyList( param ))

	return true

}
