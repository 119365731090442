import { combineReducers } from "redux"
import { penderReducer } from "redux-pender"

import SampleReducer from "./sample"
import ConfigReducer from "./config"
import SignReducer from "./sign"
import AdminReducer from "./admin"
import CommonReducer from "./common"

export default combineReducers({
  pender: penderReducer,
  SampleReducer,
  ConfigReducer,
  SignReducer,
  AdminReducer,
  CommonReducer,
})
