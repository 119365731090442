// 로그인
import React, { useState, useEffect } from "react"
import { navigate, Link } from "gatsby"
import styled from "styled-components"
import { WidthToDP, isApp, isIPhone } from "../../utils/util"

import SignContainer from "../../containers/SignContainer"
import SEO from "../seo"
import CircleCheckBox from "../component/common/CircleCheckBox"
import { GreenButton } from "../component/common/Button"
import BasicPopup from "../popup/BasicPopup"
// import icon_logo from "images/defaultImg.png"
import icon_logo from "images/elefarm.png"

const Login = (props) => {
  const { postSignIn } = props
  const [popup, setPopup] = useState({ open: false, text: "" })
  const [id, setId] = useState("")
  const [password, setPassword] = useState("")
  const [check, setCheck] = useState(false)

  useEffect(() => {
    window.NativeLoginDataReceive = async (_data) => {
      loginFunction({
        signType: _data.LoginData.SignType,
        userId: _data.LoginData.ID,
        userPw: _data.LoginData.Password,
        isAutoLogin: _data.LoginData.IsAutoLogin,

        snsToken: "",
        pushToken: _data.LoginData.FCMToken,
        deviceNo: _data.LoginData.DeviceNo,
      })

      localStorage.setItem("deviceNo", _data.LoginData.DeviceNo)

      return "success"
    }

    if (localStorage.getItem("userId")) {
      setId(localStorage.getItem("userId"))
      setCheck(true)
    }
  }, [])

  const loginFunction = async (data) => {
    const result = await postSignIn(data)

    if (result) {
      if (check) localStorage.setItem("userId", id)
      else localStorage.removeItem("userId")

      navigate("/app/main")
    }
  }

  const onClickLogin = async () => {
    if (id === "" || password === "") return setPopup({ open: true, text: "아이디 및 비밀번호를 확인해주세요." })

    const data = {
      signType: 0,
      userId: id,
      userPw: password,
      isAutoLogin: "1",
      snsToken: "",
      pushToken: "",
      deviceNo: "WEB",
    }

    if (isApp) {
      window.callNative(
        JSON.stringify({
          Type: "GetFCMToken",
          FunctionName: "NativeLoginDataReceive",
          data: {
            LoginData: {
              ID: data.userId,
              Password: data.userPw,
              SignType: data.signType,
              IsAutoLogin: data.isAutoLogin,
            },
          },
        })
      )
    } else {
      loginFunction(data)
    } // end if
  }

  return (
    <Container>
      <SEO title="로그인" />
      <BasicPopup open={popup.open} onClose={() => setPopup({ open: false, text: "" })} content={popup.text} selectedValue={["확인"]} />

      <LogoSection>
        <img style={{ width: "49px", height: "49px", marginRight: "16px" }} src={icon_logo} alt={"로고"} />
        <Title>스마트팜 운영 관리</Title>
      </LogoSection>

      <form
        onSubmit={(e) => {
          e.preventDefault()
          onClickLogin()
        }}
      >
        <InputSection>
          <InputBox>
            <InputLabel>아이디</InputLabel>
            <Input type={"text"} placeholder="아이디를 입력하세요." value={id} onChange={(e) => setId(e.target.value)} />
          </InputBox>

          <InputBox>
            <InputLabel>비밀번호</InputLabel>
            <Input type={"password"} placeholder="비밀번호를 입력하세요." value={password} onChange={(e) => setPassword(e.target.value)} />
          </InputBox>
        </InputSection>

        <BottomSection>
          <CircleCheckBox text="아이디 저장" check={check} setCheck={setCheck} />

          <div style={{ width: "200px" }}>
            <GreenButton type="submit" onClick={onClickLogin}>
              로그인
            </GreenButton>
            {/* <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "26px" }}>
            <StyledLink to="/app/signup">회원가입</StyledLink>
            <div style={{ backgroundColor: "#dbdbdb", width: "1px", height: "21px" }} />
            <StyledLink to="/app/changePw">비밀번호 재설정</StyledLink>
          </div> */}
          </div>
        </BottomSection>
      </form>
    </Container>
  )
}

const Container = styled.div`
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`
const LogoSection = styled.section`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 528px;
`
const Title = styled.p`
  color: #555555;
  font-size: 20px;
  font-weight: 500;
  line-height: -0.45px;
`
const InputSection = styled.div`
  background-color: #fff;
  margin-top: 16px;
  padding: 55px 60px 55px 56px;
  border: 1px solid #fff;
  border-radius: 9px;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
`
const InputBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  &:last-child {
    margin-top: 20px;
  }
`
const InputLabel = styled.p`
  opacity: 80%;
  width: 90px;
  margin-bottom: 5px;
  margin-right: 22px;
  color: #555555;
  font-size: 16px;
  line-height: -0.45px;
`
const Input = styled.input`
  display: block;
  width: 300px;
  height: 40px;
`
const BottomSection = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 528px;
  margin-top: 26px;
`
const StyledLink = styled(Link)`
  cursor: pointer;
  opacity: 80%;
  color: #555555;
  font-size: 14px;
  text-decoration: none;
`

export default SignContainer(Login)
