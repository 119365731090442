// 품목 추가/수정 팝업
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import moment from "moment"

import { makeStyles } from "@material-ui/styles"
import { Dialog } from "@material-ui/core"

import AdminContainer from "../../containers/AdminContainer"

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    margin: "0 auto",
    "& .MuiDialog-paperFullWidth": {
      width: "100%",
    },
    "& .MuiPaper-root": {
      backgroundColor: "#fff",
      maxWidth: "615px",
    },
    "& .MuiDialog-paper": {
      margin: "0px",
    },
  },
}))

const ErrorLogPopup = (props) => {

  const classes = useStyles()
  
  const { 
    
    onClose, open, 
    errorMessage
  
  } = props

  useEffect(() => {

    if( open ) getData()

  }, [open])

  const getData = async () => {

  }

  return (
    <Dialog
      className={classes.container}
      style={{ position: "absolute", width: "100%", height: "100%", zIndex: 100 }}
      onClose={() => onClose()}
      open={open}
      fullWidth={true}
    >
      <div
        style={{
          backgroundColor: "#fff",
          overflowY: "hidden",
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "800px",
          padding: "23px 51px 44px 40px",
        }}
      >
        <Title>에러메시지</Title>
        <div style={{ overflow: "scroll", flex: 1, marginBottom: '14px' }}>
          {errorMessage}
        </div>
        <ButtonBox>
          <Button
            bgColor={"#fff"}
            hover={"rgba(174, 174, 174, 0.22)"}
            border={"1px solid #c6c6c6"}
            color={"#555555cc"}
            onClick={() => onClose()}
          >
            취소
          </Button>
        </ButtonBox>
      </div>
    </Dialog>
  )
}

const Title = styled.p`
  opacity: 80%;
  margin-bottom: 23px;
  color: #555555;
  font-size: 15px;
  font-weight: 700;
  line-height: 32px;
`
const InputArea = styled.div`
  flex: 1;
`
const FlexBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: ${(props) => props.alignItems || "center"};
  margin-bottom: 14px;
`
const Label = styled.p`
  opacity: 80%;
  width: 102px;
  color: #555555;
  font-size: 14px;
  line-height: 34px;
`
const Input = styled.input`
  width: ${(props) => props.width || 300}px;
  height: 40px;
`
const TextArea = styled.textarea`
  flex: 1;
  height: 80px;
  resize: none;
`
const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
const Button = styled.button`
  background-color: ${(props) => props.bgColor};
  width: 147px;
  height: 40px;
  border: ${(props) => props.border};
  border-radius: 3px;
  color: ${(props) => props.color};
  font-size: 14px;

  &:last-child {
    margin-left: 14px;
  }

  &:hover {
    background-color: ${(props) => props.hover};
  }

  @media print {
    display: none;
  }
`

ErrorLogPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}

export default AdminContainer(ErrorLogPopup)
